.l-switch {
  &.e-switch {
    &.l-switch-large {
      .e-switch__input {
        &:checked + .e-switch__toggle:before {
          transform: translate(18px, -50%);
        }
      }

      .e-switch__toggle {
        height: 20px;
        width: 34px;
        border-radius: 10px;

        &:before {
          width: 20px;
          height: 20px;
        }
      }

      .l-field__input-checkbox {
        .e-checkbox + label {
          display: block;
          font-size: 15px;
          padding-left: 25px;
          line-height: 14px;
          margin-bottom: 10px;
        }
      }
    }
  }
}
