.content-referrer {
  .sharing-channels {
    display: flex;
    align-items: center;

    > * {
      flex: 0 0 auto;
    }

    > svg {
      margin: 0 24px 0 -16px;
    }
  }
}
