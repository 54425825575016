$input_width: 300px;
$input_width_without_icon: calc(#{$input_width} - 40px);

.sales-data-configuration {
  .markets-selection {
    .e-field {
      width: $input_width;

      input {
        border-radius: 8px;
      }
    }

    .e-btn.add-new-market {
      width: $input_width_without_icon;
    }
  }
}
