@import "./mixins.scss";

.member-profile.e-layout {
  .member-details {
    position: relative;

    .e-box {
      padding: 0 15px;
      margin: 0;
      height: 230px;

      &.member-details-tier {
        background-color: #fbfbfb;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
    }

    .member-details-tier-top {
      padding: 15px 0;
      min-height: 140px;

      .e-cell {
        .font-weight-thin {
          margin-top: 4px;
        }
      }
    }

    .member-details-tier-top,
    .member-details-points-top {
      .tier-icon {
        @include circle(80px, #ccc, 2px);
        text-align: center;
        position: relative;
        margin-right: 20px;

        > img {
          width: 60%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          max-height: 60px;
          max-width: 60px;
        }
      }
    }

    .member-details-tier-bottom,
    .member-details-points-bottom {
      display: flex;
      height: 100%;
      border-top: 1px solid #ddd;
      margin: 0 -15px;
      font-size: 15px;

      > div {
        padding: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        border-left: 1px solid #ddd;
        width: 30%;

        &:nth-of-type(3) {
          width: 40%;
        }

        &:first-child {
          border-left: 0px;
        }

        > div {
          > span {
            font-size: 12px;
            font-weight: 300;
          }
        }
      }

      &.two-cells {
        > div {
          width: 100%;
        }
      }
    }

    .member-details-points {
      font-size: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .member-details-points-title {
        text-transform: uppercase;
        padding: 10px 15px;
        margin: 0 -15px;
      }

      .member-details-points-content {
        justify-content: flex-start;

        &.member-details-points-content_space-between {
          justify-content: space-between;
          > div {
            margin: 0;
          }
        }

        > div {
          margin-bottom: 15px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          > div:last-child {
            font-size: 20px;
          }
        }
      }
    }

    .disabled-section {
      color: #c7d2dd;
    }

    .disabled-section:after {
      margin: 8px;
      width: calc(100% - 16px);
      border-radius: 8px;
    }
  }

  .member-activity-wrapper {
    padding: 12px 24px 24px 24px;
    margin-top: 20px;
  }

  .member-activity {
    position: relative;
    min-height: 200px;
  }

  .l-member-profile-section {
    padding: 12px 24px 0 24px;
  }
}
