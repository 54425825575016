@import "./mixins.scss";

.l-onboarding-activation {
  color: #666;

  h1 {
    color: #666;
  }

  .trophy-logo {
    @include circle(100px, #ccc, 4px);
  }

  .oval {
    display: inline-block;
    width: 7px;
    height: 7px;
    opacity: 0.5;
    background-color: #666;
    margin: 0 7px;
    border-radius: 50%;
  }
}
