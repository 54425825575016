.l-catalog-search-finder {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  height: 500px;

  > div.l-catalog-level {
    flex: 0 0 auto;
    padding: 10px 0;
    width: 25%;
    overflow-y: auto;
    overflow-x: hidden;

    e-tooltip {
      display: block;
    }

    div.l-catalog-item {
      padding: 10px 15px;
      display: flex;

      &.category {
        &:hover {
          background: #f3f3f3;
        }

        &.l-item-selected {
          background: #f3f3f3;
          font-weight: 500;
        }

        > label {
          cursor: pointer;

          > e-icon {
            position: absolute;
            right: -20px;
            top: -2px;
            width: 20px;
          }
        }
      }

      > * {
        flex: 0 0 auto;
      }

      > label,
      > e-tooltip {
        max-width: 210px;
        position: relative;
        flex-grow: 1;
      }
    }
  }
}
