.l-icons-gallery {
  header {
    margin-bottom: 5px;
  }
  .e-dialog__header {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .l-icons-gallery-search {
    position: absolute;
    top: 16px;
    right: 54px;
    width: 400px;

    .icon-upload {
      position: relative;
      left: 340px;
      top: 0px;
      width: 40px;
    }
  }

  .e-dialog__content {
    border-top: 1px solid #f2f2f2;
    padding: 15px !important;

    > div {
      height: 100%;

      > div.l-icons-gallery-content {
        height: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        img.e-card__preview {
          width: 100px;
        }
      }
    }
  }

  .i-icon-upload {
    margin-left: 20px;
  }
}
