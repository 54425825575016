.l-notif-preview-web {
  display: inline-block;
  width: 300px;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -150px;

  .l-fab-preview {
    background-color: #000;
    color: #fff;
    line-height: 15px;

    .loyalsys-button {
      display: flex;
      -ms-flex-direction: row;
      flex-direction: row;
      bottom: 0;
      color: #fff;
      font-size: 13px;
      cursor: pointer;
      text-transform: uppercase;
      -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

      .button-left {
        background-color: #6687c3;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 10px;
        -ms-flex: 0 0 50px;
        flex: 0 0 50px;
      }

      .button-center {
        background-color: #4069b4;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 10px;
        -ms-flex: 1 1;
        flex: 1 1;
        text-transform: none;
      }

      .button-right {
        background-color: #1f4fa7;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 10px;
        -ms-flex: 0 0 20px;
        flex: 0 0 20px;
      }

      .button-text-larger {
        font-size: 15px;
      }

      .button-text-smaller {
        font-size: 11px;
        margin-top: 2px;
      }
    }
  }
}
