.exclusive-access-template-editor {
  .editor-layout {
    padding: 0 0 36px 0 !important;
  }

  .style-editor-sidebar {
    //flex: 1 1 10%;

    .style-editor-element {
      position: relative;

      a.e-btn {
        position: absolute;
        bottom: 0;
        right: 0;
      }

      .e-field {
        line-height: 1.5;
      }
    }

    .e-verticalnavitem__value {
      display: none;
    }
  }

  .color-picker-container {
    // flex-direction: column;
    // padding: 0 20px;
    // max-height: calc(100% - 78px);
    // overflow: auto;
  }

  .exclusive-access-popup-design {
    background-color: #ffffff;
    width: 100%;
    height: 100%;
  }
}
