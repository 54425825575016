$gray-color-text: #bababa;

.l-preview-email {
  //padding: 20px 0;

  background-color: #fff;

  .l-gray-color-text {
    color: $gray-color-text;
  }

  .l-preview-email__body {
    background-color: #fff;
    margin: 0 auto;

    .l-email-loyalty-box {
      margin: 138px 0 118px 0;

      &.first-box {
        margin: 63px 0 40px 0 !important;
      }

      &.tier-preview-mode {
        margin: 40px 0;
      }

      table {
        table-layout: auto;
        border-collapse: collapse;
        width: 100%;
        white-space: nowrap;

        tr {
          border: solid 1px #ededed;
        }

        td {
          padding: 0 25px 0 15px;
          text-align: left;
          height: 60px;

          &.strech-cell {
            width: 100%;
          }

          &.cta-cell {
            border-left: none;
            padding: 0 15px;
          }

          .e-tokeninput__token {
            font-size: 13px;
          }
        }

        &.box-header {
          > tbody {
            > tr {
              border-bottom: none;

              > td {
                border-bottom: none;
                color: $gray-color-text;
                height: 33px;
                border-right: solid 1px #ededed;
                width: 33.33%;
                text-align: center;
              }
            }
          }
        }

        &.box-content {
          > tbody {
            > tr {
              > td {
                &:first-child {
                  padding: 5px 0 5px 15px;
                }

                &.strech-cell {
                  white-space: pre-line;
                }
              }
            }
          }
        }
      }
    }

    .l-email-blank-box {
      padding-bottom: 34px;

      > div {
        height: 8px;
        background-color: #d8d8d8;
        width: 188px;
        margin: 13px auto;

        &:first-of-type {
          width: 293px;
        }

        &:last-of-type {
          width: 126px;
        }
      }
    }
  }
}

.content-preview__wrapper {
  padding-top: 24px;
}
