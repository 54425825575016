.l-alert-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 608px;
  transform: translateX(-50%);
  display: inline-block;

  > .e-alert {
    &.multiline {
      line-height: 1;
    }
  }
}
