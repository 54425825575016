.l-preview-web {
  .e-device {
    margin-top: -80px;

    .e-device__content {
      table.preview-header {
        width: 100%;

        td {
          text-align: center;
          width: 50%;
          background-color: #fff;
          height: 60px;

          &:last-of-type {
            border-left: solid 1px #ededed;
            color: #b8b8b8;
          }
        }
      }

      .l-action-message {
        margin: 20px 20px 0;
      }
    }
  }
}
