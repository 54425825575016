.option_box_container {
  margin-top: 20px;
}

.link {
  display: flex;
  align-items: center;
  height: 100px !important;
  color: rgba(0, 0, 0, 0.7) !important;
}

.link:first-child {
  margin-bottom: 10px;
}

#option_box_img {
  margin: 0 20px;
}
