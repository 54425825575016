.e-field {
  .e-field__prefix {
    display: block;

    .e-field__prefix__icon.l-prefix-icon-bordered {
      line-height: 40px;
      width: 40px;
      border-right: 1px solid #c8ceca;
      padding: 0;
      text-align: center;
      left: 0;
      margin-top: 0;
    }
  }

  .l-field__postfix {
    width: 40px;
    height: 40px;
  }
}

.l-prefix-input {
  padding-left: 45px !important;
}
