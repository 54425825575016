.l-loader-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center;
  z-index: 999;
  background-color: #fff;
  opacity: 0.8;

  &.l-loader-overlay_no-opacity {
    opacity: 1;
  }

  e-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -26px 0 0 -26px;
  }
}
