.l-select-with-button {
  display: flex !important;
  align-items: center;
}

.toggle-view-button__container {
  position: absolute;
  top: 3px;
  right: 2px;
}

.side-button {
  margin-left: 10px;
}

.aligned {
  margin-top: 18px;
  margin-left: 10px;
}
