$spaceamounts: (
  -10,
  -15,
  0,
  5,
  10,
  15,
  20,
  25,
  30,
  40,
  50,
  60,
  75,
  100
); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right, all); // Leave this variable alone

@each $space in $spaceamounts {
  @each $side in $sides {
    @if $side == "all" {
      @if $space >= 0 {
        .m-#{$space} {
          margin: #{$space}px;
        }

        .p-#{$space} {
          padding: #{$space}px;
        }
      } @else {
        .m-n#{$space} {
          margin: #{$space}px;
        }
      }
    } @else {
      @if $space >= 0 {
        .m-#{str-slice($side, 0, 1)}-#{$space} {
          margin-#{$side}: #{$space}px !important;
        }

        .p-#{str-slice($side, 0, 1)}-#{$space} {
          padding-#{$side}: #{$space}px !important;
        }
      } @else {
        .m-n-#{str-slice($side, 0, 1)}#{$space} {
          margin-#{$side}: #{$space}px !important;
        }
      }
    }
  }
}
