.member-profile-activity {
  .search-header {
    padding: 12px 0 12px 15px;
    border: 1px solid #ededed;
    border-bottom: none;
  }

  .e-accordion {
    > input:disabled + .e-accordion__title {
      color: inherit !important;
    }

    .e-accordion__title {
      display: flex;

      &:first-child {
        &::after {
          visibility: hidden;
        }
        &:hover {
          background: transparent;
          cursor: auto;
        }
      }

      span {
        flex: 0 0 200px;
        margin: 0 3px;

        &:first-child,
        &:last-child {
          flex: 0 0 125px;
        }

        &:nth-child(2) {
          flex: 1;
          min-width: 150px;
          max-width: 500px;
        }
      }
    }

    .e-accordion__content .e-table {
      table-layout: fixed;

      > colgroup > col:first-child,
      > colgroup > col:last-child {
        width: 200px;
      }
    }
  }
}
