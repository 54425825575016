.l-action-message {
  position: relative;
  background-color: #fff;
  border: solid 1px #ededed;

  .l-action-message-close {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  table.l-action-message-content {
    &.has-close {
      td:last-of-type {
        padding-right: 25px;
      }
    }

    td {
      padding: 15px;

      &:last-of-type {
        padding-left: 0;
      }
    }
  }

  &.l-action-message-blank {
    padding: 15px;

    > div {
      height: 8px;
      background-color: #d8d8d8;
      width: 252px;
      margin: 10px;

      &:last-of-type {
        width: 124px;
      }
    }
  }
}

.l-action-message-icon {
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  border: solid 1px #ccc;

  e-icon > svg {
    fill: #ccc;
  }
}
