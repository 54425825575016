.l-distribution {
  max-height: 830px;

  .e-fullheight__container {
    left: 0;
    right: 0;
  }

  .e-box {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .e-row,
  .e-col-6 {
    height: 100%;
  }

  .e-col__separator {
    height: 97%;
  }

  .onboarding-tiers-settings {
    .e-grid.e-grid-between {
      .e-cell {
        display: flex;
        align-items: baseline;
      }
      div.e-cell {
        .e-field {
          width: 100%;

          .e-input:disabled {
            padding: 0 1em;
          }
        }
      }
      span.e-cell {
        margin: 0 15px;
        padding-top: 25px;
        width: 42px;
        margin-top: 11px;
        justify-content: center;
        align-self: flex-start;
      }
    }
  }

  .onboarding-distribution-preview {
    .e-header.e-header-flex {
      margin: 0 0 15px 0;
      align-items: center;
    }
    .onboarding-tiers-chart {
      position: relative;
    }
  }
}

.e-grid.e-grid-between.l-grid-distribution {
  div.e-cell {
    flex: 1;
  }
  span.e-cell {
    width: 42px;
    margin-top: 11px;
    text-align: center;
    align-self: center;
  }
}
