nav ul {
  list-style: none;
  display: flex;
  background-color: black;
  margin-top: 0px;
  margin-bottom: 40px;

  li {
    padding: 20px;

    a {
      color: white;
      text-decoration: none;

      &.active {
        border-bottom: 4px solid white;
      }
    }
  }
}

.l-navbar {
  display: flex;
  justify-content: space-between;
}
