.l-card.e-card {
  max-height: 320px;

  &.default {
    background-color: #fafafa;
  }

  &.e-card-emptystate {
    border-style: solid;
  }

  .l-card-top-row {
    height: 40px;
  }

  .e-card__content {
    padding: 20px;

    .l-card-desc {
      white-space: pre-line;
    }

    .l-card-actions {
      position: absolute;
      height: 23px;
      bottom: 20px;
      left: 20px;
      right: 20px;
      align-items: center;
      margin-right: -10px;
    }
  }

  .l-roadmap-overlay {
    > .e-label {
      position: absolute;
      left: 20px;
      bottom: 20px;
    }
  }

  .l-card-badges {
    .e-label:not(:first-child) {
      margin-left: 5px;
    }
  }
  .l-card-badges > .e-label.l-card-label-tactic {
    background-color: #39aad3 !important;
    color: #fafafa;
  }

  .l-card-badges > .e-label.l-card-label-default {
    background-color: #5b5b5b !important;
    color: #fafafa;
  }

  .l-card-badges > .e-label.l-card-label-promotion {
    background-color: #d86760 !important;
    color: #fafafa;
  }

  .l-card-badges > .e-label.l-card-label-promotion-recurrence {
    background-color: #d08014 !important;
    color: #fafafa;
  }
  &.join {
    .l-card-badges > .e-label {
      background-color: #00c9b4;
      color: #fafafa;
    }
  }

  &.purchases {
    .l-card-badges > .e-label {
      background-color: #7993ad;
      color: #fafafa;
    }
  }

  &.promotions {
    .l-card-badges > .e-label {
      background-color: #9f6e5d;
      color: #fafafa;
    }
  }

  &.engagements {
    .l-card-badges > .e-label {
      background-color: #eca24b;
      color: #fafafa;
    }
  }

  &.events {
    .l-card-badges > .e-label {
      background-color: #6e71c2;
      color: #fafafa;
    }
  }

  &.redemption {
    .l-card-badges > .e-label {
      background-color: #9f6e5d;
      color: #fafafa;
    }
  }

  &.referral {
    .l-card-badges > .e-label {
      background-color: #99b6d9;
      color: #fafafa;
    }
  }

  &.cash {
    .l-card-badges > .e-label {
      background-color: #eca24b;
      color: #fafafa;
    }
  }

  &.discount {
    .l-card-badges > .e-label {
      background-color: #eca24b;
      color: #fafafa;
    }
  }

  &.service {
    .l-card-badges > .e-label {
      background-color: #7993ad;
      color: #fafafa;
    }
  }

  &.gift {
    .l-card-badges > .e-label {
      background-color: #7993ad;
      color: #fafafa;
    }
  }

  &.exclusiveAccess {
    .l-card-badges > .e-label {
      background-color: #00c9b4;
      color: #fafafa;
    }
  }

  &.vouchersForPoints {
    .l-card-badges > .e-label {
      background-color: #eca24b;
      color: #fafafa;
    }
  }

  &.vouchersForRewards {
    .l-card-badges > .e-label {
      background-color: #7993ad;
      color: #fafafa;
    }
  }

  &.vouchersForFree {
    .l-card-badges > .e-label {
      background-color: #00c9b4;
      color: #fafafa;
    }
  }

  &.permanent,
  &.limited {
    .l-card-badges > .e-label {
      background-color: #08cab6;
      color: #fafafa;
    }
  }

  .l-card-badges {
    & > .e-label.l-card-label-voucherType,
    & > .e-label.l-card-label-poolType {
      background-color: #0f344a !important;
    }
  }
}
