.l-dashboard-iframe-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;

  > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.l-dashboard {
  e-widget {
    section.e-widget__content {
      position: relative;
      overflow: hidden;
      transition: height 0.3s ease-in-out;

      &.e-widget__content-collapsed {
        height: 48px !important;
        transition: height 0.3s ease-in-out;
      }

      &.empty-state {
        flex-direction: row;
        align-items: center;

        .e-emptystate__content {
          max-width: 400px;
        }

        .e-emptystate__title {
          font-size: 32px;
          font-weight: 500;
          line-height: 40px;
          color: #233d56;
        }

        .e-emptystate__lead {
          font-size: 20px;
          font-weight: 500;
          line-height: 24px;
          color: #233d56;
        }

        .e-emptystate__icon {
          border: none;
        }
      }
    }

    &.generated-revenue-widget,
    &.referral-revenue-widget {
      section.e-widget__content {
        height: 370px;
      }
    }

    &.purchase-freq-widget,
    &.aov-widget {
      section.e-widget__content {
        min-height: 430px;
      }
    }

    &.num-of-members-widget,
    &.enrollments-widget,
    &.points-analytics-widget,
    &.vouchers-analytics-widget,
    &.referral-success-widget,
    &.referral-vouchers-widget {
      section.e-widget__content {
        height: 352px;
      }
    }

    &.engagement-rates-widget {
      section.e-widget__content {
        height: 440px;
      }
    }
  }
  .e-legend {
    .e-legend__value.text-size-headline {
      line-height: 40px;
    }

    .e-legend__title {
      line-height: 24px;
    }

    .e-legend__description {
      line-height: 20px;
    }

    .e-legend__trend {
      margin-bottom: 7px;
    }
  }

  .e-fieldset__item.e-fieldset__item-chart {
    white-space: nowrap;
  }

  .l-legend-selector {
    > span {
      display: inline-block;
      white-space: nowrap;
      color: #c9c9c9;

      &:hover,
      &.l-legend-item_selected {
        color: #000 !important;
      }

      &:hover {
        color: #000 !important;
      }

      > span {
        font-size: 24px;
        margin: -1px 2px 0 0;
      }
    }
  }

  .l-widget-tabs {
    position: relative;
    margin: -16px -16px 0;

    &::before {
      background-color: #c4d0dd;
      bottom: 0;
      content: "";
      height: 2px;
      left: 0;
      position: absolute;
      right: 0;
    }

    .e-tabs__title {
      width: 100%;
      padding: 16px;
    }
  }
}
