.l-plan-select {
  padding: 100px 0;

  .l-plan-cards-wrapper {
    margin: 30px 0 15px 0;
    > div {
      display: inline-block;
      padding: 15px;

      .e-card {
        background-color: #beccdc !important;

        .e-card__caption {
          .e-label.e-label-secondary {
            align-self: center;
          }
        }

        .e-card__description {
          height: 190px;
        }

        .l-roadmap-overlay {
          > .e-label {
            position: absolute;
            bottom: 15px;
            right: 15px;
          }
        }
      }
    }
  }
}
