.benefits-setup {
  div.benefit-setup-tiers {
    margin: 0 -15px;
    > div {
      width: 25%;
      display: inline-block;
      padding: 0 15px;
      vertical-align: top;
    }
  }
}
