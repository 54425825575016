.ea-custom-html-editor {
  .editor-layout {
    padding: 0 0 36px 0 !important;
  }
  .e-fullheight__body {
    position: relative;
  }

  .e-verticalnav__body {
    position: relative;
    margin: 0;
    padding: 0;
  }

  .empty-content-placeholder {
    color: #ccc;
    left: 50%;
    min-width: 500px;
    pointer-events: none;
    position: absolute;
    text-align: center;
    top: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 17;

    > .empty-content-placeholder-icon {
      background: #eee;
      border-radius: 60px;
      display: inline-block;
      height: 120px;
      margin-bottom: 20px;
      padding: 20px;
      width: 120px;

      .e-icon {
        fill: #fff;
        height: 80px;
        width: 80px;
      }
    }

    > .empty-content-placeholder-heading {
      font-size: 28px;
      margin: 0 auto 20px;
      width: 80%;
    }

    > .empty-content-placeholder-content {
      font-size: 18px;
      margin: auto;
      width: 80%;
    }
  }
}
