.l-tiers {
  .l-tiers-cards {
    .e-cell {
      .l-card {
        .l-card-title.has-icon {
          background: no-repeat;
          background-size: 20px;
          padding-left: 25px;
        }
      }
    }
  }
}
