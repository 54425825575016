@import "./mixins.scss";

e-device-push {
  overflow-y: scroll;
}

.referral-content-preview {
  text-align: center;
  padding: 10px;
  font-size: 16px;
  color: #1d1d26;

  .small-text {
    font-size: 13px;
  }

  .color-grey {
    color: #77777d;
  }

  .consent-link {
    text-decoration: underline;
  }

  .multi-line {
    display: inline-block;
    text-align: left;
    white-space: pre-line;
  }

  .link-copy-area {
    display: flex;
    border: 2px dashed #666;
    padding: 10px;
    line-height: 38px;

    .link-copy-url {
      flex: 1 1;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .link-copy-button {
      border: 1px solid #666;
      min-width: 72px;
      height: 40px;

      padding: 0 16px;
    }
  }

  .voucher-icon {
    @include circle(80px, #ccc, 2px);
    padding: 16px;
  }

  .referral-input-preview {
    width: 100%;
    padding: 10px;
    border: 1px solid #a8a8a8;
    height: 60px;
    line-height: 38px;
    text-align: center;
  }

  .referral-checkbox-preview {
    display: inline-block;
    text-align: left;

    input[type="checkbox"] {
      visibility: hidden;
      width: 1.25em;
      margin: 0;
      display: block;
      float: left;
      font-size: inherit;
    }

    label {
      display: inline;
      position: relative;
      line-height: 1.25em;
      vertical-align: top;
      clear: both;

      &:before {
        width: 1.25em;
        height: 1.25em;
        background: #fff;
        border: 2px solid rgba(0, 0, 0, 0.54);
        border-radius: 0;
        content: "";
        position: absolute;
        left: -20px;
        top: 0;
      }
    }
  }

  .sharing-channels {
    > svg {
      margin: 0 10px;
    }
  }
}
