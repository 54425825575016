.e-datagrid-container {
  position: relative;
}

.create-btn {
  position: absolute;
  top: 12px;
  right: 15px;
  background: #81c980 !important;
  border-color: #6fc16e !important;
  color: #fff !important;
}

.create-btn:hover {
  background: #6fc16e;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
  color: #fff;
}
