$input_width: 300px;
$input_width_without_icon: calc(#{$input_width} - 40px);

.language-and-currency {
  &.view-mode {
    .additional-section {
      .e-field {
        width: $input_width_without_icon !important;
      }
    }
  }

  > .e-row {
    > div.section {
      // float: left;
      // padding-left: 12px;
      // padding-right: 12px;
      // width: 30%;

      &.section_language {
        // width: 40%;
        .additional-section {
          .e-field {
            width: $input_width_without_icon;
          }
          .store-url-field .e-field {
            width: $input_width;
          }
        }
      }

      .primary-section {
        .e-field {
          width: $input_width_without_icon;
        }
      }
      .additional-section {
        .e-field {
          width: $input_width;
        }

        .add-new-button {
          width: $input_width_without_icon;
        }
      }
    }
  }
}
