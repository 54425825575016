@mixin selected-button {
  background-color: #f2f2f2;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
}

@mixin gray-box {
  background-color: #fbfbfb;
  border: 1px solid #ddd;
}

@mixin circle($width, $border-color, $border-size) {
  display: inline-block;
  color: $border-color;
  width: $width;
  height: $width;
  border: $border-size solid $border-color;
  line-height: $width - 2 * $border-size;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

@mixin forceScrollbars() {
  // For Google Chrome
  &::-webkit-scrollbar {
    width: 11px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, 0.5);
  }
}
