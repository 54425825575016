.benefits-overview {
  .add-new-benefit-btn.e-btn {
    width: 170px;
  }

  .benefits-overview-table {
    position: relative;
    margin: auto;
    overflow: hidden;
    border-top: 1px solid #e3e6e4;
    text-align: left;
    border-radius: 0 0 8px 8px;

    .ReactTable {
      border: none;

      .rt-thead.-header {
        box-shadow: none;

        .rt-th {
          font-weight: 400;
          background-color: #fbfbfb;
          padding: 20px 15px;
          text-align: left;
          line-height: 1.5;
          display: flex;
          flex-direction: column;
          justify-content: center;
          border-right: none;

          &:first-child {
            border-right: 1px solid #e3e6e4;
          }

          &:last-child {
            border-left: 1px solid #e3e6e4;
          }

          th {
            font-weight: 400;
          }
        }
      }

      .rt-tr-group {
        border-bottom: none;
        border-top: 1px solid #e3e6e4;
      }
      .rt-tbody {
        .rt-td {
          padding: 12px 15px;
          white-space: pre-line;
          border-right: none;

          &:first-child {
            border-right: 1px solid #e3e6e4;
          }

          &:not(:last-child) {
            display: flex;
            flex-direction: column;
            justify-content: center;
          }

          &:last-child {
            display: flex;
            align-items: center;
            justify-content: center;
            border-left: 1px solid #e3e6e4;
          }
        }
      }
    }
  }
}
