@import "spacingHelpers";

#root {
  height: 100%;
}

.app-main {
  position: relative;
}

.box-padding {
  padding: 16px;
}

.no-padding {
  padding: 0 !important;
}
.no-margin {
  margin: 0 !important;
}
.no-border {
  border: none !important;
}
.no-background {
  background: transparent !important;
}

.gray-box {
  border: 1px solid #c8ceca;
  background-color: #fbfbfb;
  padding: 0 1.1em;
}

.gray-border {
  border: 1px solid #c8ceca;
}

ul.e-list {
  &.l-list-bordered {
    border: solid 1px #c8ceca;
    border-bottom: none;

    > li {
      padding: 12px 13px;
      border-bottom: solid 1px #c8ceca;
    }
  }
}

.l-overlay-disabled {
  position: relative;
  cursor: not-allowed;

  &:after {
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    background-color: rgba(255, 255, 255, 0.5);
  }
}

.l-disabled {
  opacity: 0.5;
}

.l-roadmap-overlay {
  cursor: not-allowed;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
}

.l-full-height {
  max-height: calc(100vh - 260px);
  overflow-y: auto;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.e-numberinput {
  .e-numberinput__arrow {
    display: none !important;
  }
}

.text-line-through {
  text-decoration: line-through;
}

.l-table {
  display: table;
  width: 100%;

  .l-table-row {
    display: table-row;
  }

  .l-table-cell {
    display: table-cell;
  }

  &.l-table-middle {
    .l-table-cell {
      vertical-align: middle;
    }
  }

  &.l-table-top {
    .l-table-cell {
      vertical-align: top;
    }
  }
}

e-time {
  white-space: nowrap;
}

.inline-block {
  display: inline-block;
}

.e-tagselector_popup.e-popover {
  border: 1px solid #c4d0dd;
  box-shadow: 0 8px 24px 0 rgba(16, 47, 67, 0.2);
}

.font-weight-light {
  font-weight: 300;
}

.l-device_no-content {
  .e-device__content {
    e-device-push-container {
      display: none;
    }
  }
}

.e-list-horizontal {
  li.e-list__item {
    display: inline-block;
  }
}

.e-layout.l-layout-fullheight {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

label.e-field__label::first-letter {
  text-transform: uppercase;
}
