@import "./mixins.scss";

.multi-lang-popup {
  .e-dialog__content {
    @include forceScrollbars();
  }

  hr.e-separator {
    margin: 16px -16px;
  }
}
